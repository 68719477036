/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function() {
                // JavaScript to be fired on all pages
            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
            // Home page
            'home': {
            init: function() {
                // JavaScript to be fired on the home page
            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function() {
                // JavaScript to be fired on the about us page
            }
        }
    };

// The routing fires all common scripts, followed by the page specific scripts.
// Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events

    $(document).ready(UTIL.loadEvents);

    var browserH = $(window).height();
    var browserW = $(window).width();
    $(window).load(function(){
        browserH = $(window).height();
        browserW = $(window).width();
        $(document).foundation({
            orbit: {
                animation: 'fade', // Sets the type of animation used for transitioning between slides, can also be 'fade'
                timer_speed: 5000, // Sets the amount of time in milliseconds before transitioning a slide
                pause_on_hover: true, // Pauses on the current slide while hovering
                resume_on_mouseout: false, // If pause on hover is set to true, this setting resumes playback after mousing out of slide
                next_on_click: false, // Advance to next slide on click
                animation_speed: 800, // Sets the amount of time in milliseconds the transition between slides will last
                stack_on_small: false,
                navigation_arrows: true,
                slide_number: false,
                slide_number_text: 'of',
                container_class: 'orbit-container',
                stack_on_small_class: 'orbit-stack-on-small',
                next_class: 'orbit-next', // Class name given to the next button
                prev_class: 'orbit-prev', // Class name given to the previous button
                timer_container_class: 'orbit-timer', // Class name given to the timer
                timer_paused_class: 'paused', // Class name given to the paused button
                timer_progress_class: 'orbit-progress', // Class name given to the progress bar
                slides_container_class: 'orbit-slides-container', // Class name given to the slide container
                preloader_class: 'preloader', // Class given to the preloader
                slide_selector: 'li', // Default is '*' which selects all children under the container
                bullets_container_class: 'orbit-bullets',
                bullets_active_class: 'active', // Class name given to the active bullet
                slide_number_class: 'orbit-slide-number', // Class name given to the slide number
                caption_class: 'orbit-caption', // Class name given to the caption
                active_slide_class: 'active', // Class name given to the active slide
                orbit_transition_class: 'orbit-transitioning',
                bullets: true, // Does the slider have bullets visible?
                circular: true, // Does the slider should go to the first slide after showing the last?
                timer: false, // Does the slider have a timer active? Setting to false disables the timer.
                swipe: true,
            },
            abide: {
                live_validate : true,
                validators: {
                    brokerVal: function(el, required, parent){
                        var selects = $('.ifbroker').find('option:selected');
                        var selectedValue = selects.val();
                        if(selectedValue == "Yes"){
                            document.getElementById("entry_1279274490").required = true;
                            document.getElementById("entry_1279274490").setAttribute("aria-required", "true");
                            document.getElementById("entry_1279274490").placeholder='NAME OF BROKER*';
                            document.getElementById("entry_14061645").required = true;
                            document.getElementById("entry_14061645").setAttribute("aria-required", "true");
                            document.getElementById("entry_14061645").placeholder='BROKERAGE FIRM*';
                        }else{
                            document.getElementById("entry_1279274490").required = false;
                            document.getElementById("entry_1279274490").setAttribute("aria-required", "false");
                            document.getElementById("entry_1279274490").placeholder='NAME OF BROKER';
                            document.getElementById("entry_14061645").required = false;
                            document.getElementById("entry_14061645").setAttribute("aria-required", "false");
                            document.getElementById("entry_14061645").placeholder='BROKERAGE FIRM';
                        }
                        return true;
                    },
                    hearVal: function(el, required, parent){
                        console.log('hear running');
                        var selects = $('.howHear').find('option:selected');
                        var selectedValue = selects.val();
                        if(selectedValue == "Other" && browserW > 768){
                            $('.other').fadeIn();
                            $('.other_sm').hide();
                        }else if(selectedValue == "Other" && browserW <= 768){
                            $('.other_sm').fadeIn();
                            $('.other').hide();
                        }else{
                            $('.other').fadeOut();
                            $('.other_sm').fadeOut();
                        }
                        return true;
                    }
                }
            }
        });
        $(".order").lettering();
        $('img.down').click(function(){
            $('body, html').animate({'scrollTop' : browserH}, 1000);
        });
        //$('ul.orbit-slides-container').css({'height' : browserH});
        $('.item .expand').click(function(){
            var thisIndex = $('.item .expand').index(this);
            $('body, html').animate({'scrollTop' : 0}, 1000);
            $('.submenu_wrapper').css({'position' : 'relative'});
            $('ol.orbit-bullets li').eq(thisIndex).trigger('click');
            $('.slideshow_wrapper').slideDown(1000, function(){
                $('body, html').scrollTop(0);
                $('.orbit-container').fadeIn(600);
            });

        });
        $('.btn_close').click(function(){
            $('.orbit-container').hide();
            $('.slideshow_wrapper').slideUp(1000, function(){
                $('body, html').scrollTop(0);
                $('.submenu_wrapper').css({'position' : 'fixed'});
            });
        });

        $('.btn_menu').click(function(){
            $('.overlay_menu').fadeIn(800);
        });
        $('.btn_close').click(function(){
            $('.overlay_menu').fadeOut(800);
        });

        $('.btn_prev').click(function(){
            $('.orbit-prev').trigger('click');
        });
        $('.btn_next').click(function(){
            $('.orbit-next').trigger('click');
        });

        $('input, textarea').placeholder();

        $('.bed_set').each(function(){
            var unitL = $(this).find('.available_unit').length;
            var h2 = $(this).find('h2');
            if(unitL <= 0){
                h2.hide();
            }
        });
        $(".one-post").hover(
            function() {
                $( this ).find('.thumb').fadeOut(500);
            }, function() {
                $( this ).find('.thumb').fadeIn(500);
            }
        );

    });
    $(window).resize(function(){
        browserH = $(window).height();
        //$('ul.orbit-slides-container').css({'height' : browserH+'!important'});
    });
})(jQuery); // Fully reference jQuery after this point.

